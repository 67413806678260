@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');

.banner {
  background-color: #203653;
  display:flex;
  flex-direction: row;
  height: 15vw;
  width: auto;
  justify-content: center;
}

.logo{
  width: 15vw;
}

.banner h1{
  color: white;
  font-family: Monserrat, sans-serif;
  font-size: 9vw;
  font-weight: 900;
  display: flex;
  align-items: center;
}
