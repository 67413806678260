@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');

.insult {
 margin: 10px;
}

.display-1{
  font-size: 5vw;
}

.btn.btn-primary.generate{
  background-color: #203653;
}

.btn.btn-primary.speak{
  background-color: #ff002b;
  border-color: #ff5f7a;
}